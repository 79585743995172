import { ButtonProps } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useSendSignatureLink } from '@src/data/api/worksheet-api/worksheet-api'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'

interface Props extends ButtonProps {
  creditApp: CreditApplication
}

const SignDigitallyButton = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [sendSignatureLink, isSendingSignatureLink, isSendingSignatureLinkError, isSendingSignatureLinkSuccess] =
    useSendSignatureLink(creditApp.financingProgramId, creditApp.id)
  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      isPending={isSendingSignatureLink}
      isError={isSendingSignatureLinkError}
      isSuccess={isSendingSignatureLinkSuccess}
      onClick={() => sendSignatureLink(creditApp.id)}
    >
      {t('worksheet.signDigitally')}
    </AsyncActionButton>
  )
}

export default SignDigitallyButton
