import { yupResolver } from '@hookform/resolvers/yup'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { Alert, Autocomplete, Box, Checkbox, FormControlLabel, Grid, Paper, Tab, Tabs, Typography } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import CreditConsentDialog from '@src/components/CreditConsentDialog'
import InputTextField from '@src/components/InputTextField'
import PageSpinner from '@src/components/PageSpinner'
import SelectComponent from '@src/components/SelectComponent'
import ServerErrorsAlert from '@src/components/ServerErrorsAlert'
import TabPanel from '@src/components/TabPanel'
import { useBeneficiaryTypes } from '@src/containers/ViewCreditApplicationPage/credit-hooks'
import { useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import {
  ApplicantDto,
  CreditApplication,
  DraftApplicantSchema,
  DraftCreditApplicationDto,
  DraftCreditApplicationSchema,
  FullCreditApplicationSchema,
} from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { EBeneficiaryType, ECreditApplicationStatus, EMerchantType, EServiceCategory } from '@src/types/Constants'
import translate from '@src/services/translate'
import { AxiosError } from 'axios'
import { BusinessError, validateCreditApplicationCanBeSubmittedForReview } from '../../../data/credit-app-validations'
import ApplicantParameters from './Applicant/ApplicantComponent'

interface Props {
  merchant: Merchant | null
  creditApplication: DraftCreditApplicationDto
  onSave: (data: Partial<CreditApplication>) => void
  isPending: boolean
  isError: boolean
  serverErrors: AxiosError | null
}

const CreditApplicationForm = ({ merchant, creditApplication, onSave, isPending, isError, serverErrors }: Props) => {
  const [draftMod, setDraftMod] = React.useState(true)

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<DraftCreditApplicationDto>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: creditApplication,
    resolver: draftMod ? yupResolver(DraftCreditApplicationSchema) : yupResolver(FullCreditApplicationSchema),
  })

  const { t } = useTranslation()
  const navigate = useNavigate()
  const isBanner = merchant?.merchantTypeId === EMerchantType.Banner
  const [merchantsList] = useMerchantByParentId(merchant?.id ?? '')
  const [searchParams] = useSearchParams()
  const [hasCoapplicantChecked, setCoApplicantChecked] = useState<boolean>(!!creditApplication.coApplicant)
  const [openConsentDialog, setOpenConsentDialog] = useState(false)
  const [consentChecked, setConsentChecked] = useState(false)
  const [globalErrors, setGlobalErrors] = useState<BusinessError[]>([])

  const loanPurposeId = watch('loanPurposeId') as EServiceCategory
  const beneficiaryTypeId = Number(watch('beneficiaryTypeId')) as EBeneficiaryType
  const isVeterinaryLoanPurpose = EServiceCategory.Veterinary === loanPurposeId
  const isGoodAndServiceLoanPurpose = EServiceCategory.GoodsAndServices === loanPurposeId
  const disableOtherName = beneficiaryTypeId !== EBeneficiaryType.Other
  const showTabIndex = searchParams.get('showTabIndex')
  const tabIndex = Number(showTabIndex) ?? 0
  const editDisabled = creditApplication?.editLocked

  useEffect(() => {
    if (merchant && !isBanner) {
      setValue('merchantId', merchant.id, { shouldValidate: true })
      setValue('loanPurposeId', merchant?.serviceCategory ?? '', { shouldValidate: true })
    }
    if (isVeterinaryLoanPurpose) {
      setValue('beneficiaryTypeId', EBeneficiaryType.Other, { shouldValidate: true })
      setValue('otherBeneficiaryFirstName', '', { shouldValidate: true })
    }
    if (beneficiaryTypeId === EBeneficiaryType.Applicant || beneficiaryTypeId === EBeneficiaryType.Coapplicant) {
      setValue('otherBeneficiaryFirstName', '', { shouldValidate: true })
      setValue('otherBeneficiaryLastName', '', { shouldValidate: true })
    }
    if (serverErrors) {
      window.scrollTo(0, 0)
    }
  }, [merchant, isBanner, setValue, isVeterinaryLoanPurpose, serverErrors, beneficiaryTypeId])

  const beneficiaryTypes = useBeneficiaryTypes(loanPurposeId)

  const handleToggleCoapplicant = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('coApplicant', DraftApplicantSchema.getDefault() as ApplicantDto)
        setValue('coApplicant.isPrimaryApplicant', false)
      } else {
        setValue('coApplicant', null)
      }
      setCoApplicantChecked(event.target.checked)
    },
    [setValue],
  )

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const { location } = window
      const updateSearch = new URLSearchParams(location.search)
      updateSearch.set('showTabIndex', encodeURIComponent(newValue))
      const newUrl = `${location.pathname}?${updateSearch.toString()}`
      navigate(newUrl, { replace: true })
    },
    [navigate],
  )

  const submit = useCallback(() => {
    onSave(getValues() as CreditApplication)
  }, [getValues, onSave])

  const handlePrequalify = useCallback(async () => {
    const isFormValid = await trigger()
    if (isFormValid) {
      const data = getValues()
      const cErrors = validateCreditApplicationCanBeSubmittedForReview(data as CreditApplication)
      setGlobalErrors(cErrors)
      if (cErrors.length <= 0) {
        data.status = ECreditApplicationStatus.Active
        data.consentSoftHit = true
        data.consentHardHit = false
        onSave(data as CreditApplication)
      }
    }
  }, [getValues, onSave, trigger])

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentChecked(event.target.checked)
  }, [])

  const handleConfirm = useCallback(async () => {
    if (consentChecked) {
      setOpenConsentDialog(false)
      await handlePrequalify()
    }
  }, [consentChecked, handlePrequalify])

  return (
    <div style={{ height: '100%' }}>
      <PageSpinner isLoading={isPending} withBackdrop />
      <Paper>{serverErrors && <ServerErrorsAlert serverErrors={serverErrors} />}</Paper>
      <Paper>
        <form onSubmit={handleSubmit(submit, reportErrorToConsole)}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4} ml={2} md={4}>
              {isBanner && (
                <Autocomplete
                  id="merchantList"
                  options={merchantsList}
                  onChange={(_event, selectedMerchant: Merchant | null) => {
                    if (selectedMerchant) {
                      setValue('merchantId', selectedMerchant.id, { shouldValidate: true })
                      setValue('loanPurposeId', selectedMerchant.serviceCategory ?? '', { shouldValidate: true })
                      if (selectedMerchant.serviceCategory === EServiceCategory.GoodsAndServices) {
                        setValue('beneficiaryTypeId', EBeneficiaryType.Applicant, { shouldValidate: true })
                        setValue('otherBeneficiaryFirstName', '', { shouldValidate: true })
                        setValue('otherBeneficiaryLastName', '', { shouldValidate: true })
                      }
                    }
                  }}
                  disabled={!isBanner}
                  autoHighlight
                  getOptionLabel={(option) => option?.name ?? ''}
                  noOptionsText={t('common.searchYieldsNoResults')}
                  renderInput={(merchantList) => (
                    <InputTextField
                      {...merchantList}
                      id="merchant"
                      label={t('common.merchant')}
                      inputProps={{
                        ...merchantList.inputProps,
                      }}
                      error={errors?.merchantId}
                    />
                  )}
                />
              )}
              {!isBanner && (
                <InputTextField
                  id="merchant"
                  value={merchant?.name ?? ''}
                  disabled
                  label={t('common.merchant')}
                  error={errors?.merchantId}
                />
              )}
            </Grid>
            <Grid item xs={3} md={3}>
              <InputTextField
                id="loanPurposeId"
                value={loanPurposeId ? t(`serviceCategory.${loanPurposeId}`) : ''}
                disabled
                label={t('editCreditApplication.loanPurpose')}
                {...register('loanPurposeId')}
                error={errors?.loanPurposeId}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <InputTextField
                id="amountRequested"
                InputProps={{
                  endAdornment: '$',
                }}
                error={errors?.requestedLoanAmount}
                label={t('editCreditApplication.amountRequested')}
                {...register('requestedLoanAmount')}
              />
            </Grid>
            {!isGoodAndServiceLoanPurpose && (
              <Grid container spacing={2} margin={2}>
                <Grid item xs={4} md={4}>
                  <SelectComponent
                    valueList={beneficiaryTypes}
                    label={t('editCreditApplication.beneficiary.beneficiary') as string}
                    disabled={isVeterinaryLoanPurpose}
                    {...register('beneficiaryTypeId')}
                    error={errors?.beneficiaryTypeId}
                  />
                </Grid>
                {!isVeterinaryLoanPurpose && (
                  <Grid item xs={4} md={4}>
                    <InputTextField
                      id="otherBeneficiaryFirstName"
                      error={errors?.otherBeneficiaryFirstName}
                      label={t('editCreditApplication.beneficiary.otherBeneficiaryFirstName')}
                      disabled={disableOtherName}
                      {...register('otherBeneficiaryFirstName')}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                )}
                <Grid item xs={4} md={4}>
                  <InputTextField
                    id="otherBeneficiaryLastName"
                    error={errors?.otherBeneficiaryLastName}
                    label={
                      !isVeterinaryLoanPurpose
                        ? t('editCreditApplication.beneficiary.otherBeneficiaryLastName')
                        : t('editCreditApplication.beneficiary.animalName')
                    }
                    disabled={disableOtherName}
                    {...register('otherBeneficiaryLastName')}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab
                id="applicantTab"
                icon={errors.applicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('common.applicant')}
              />
              <Tab
                id="coApplicantTab"
                icon={errors.coApplicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('common.coApplicant')}
              />
            </Tabs>
          </Box>
          <Box sx={{ flex: '1 1 auto' }} />
          <input type="hidden" value=" " {...register('id')} />
          <input type="hidden" value=" " {...register('merchantId')} />
          <TabPanel value={tabIndex} index={0}>
            <ApplicantParameters
              register={register}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              errors={errors.applicant}
              name="applicant"
              formControl={control}
              editDisabled={editDisabled}
              prohibitedPhone={watch('coApplicant.cellPhone')}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <FormControlLabel
              control={
                <Checkbox id="toggleCoapplicant" checked={hasCoapplicantChecked} onChange={handleToggleCoapplicant} />
              }
              label={t('common.coApplicant')}
            />
            {hasCoapplicantChecked && (
              <ApplicantParameters
                register={register}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                errors={errors?.coApplicant}
                name="coApplicant"
                formControl={control}
                editDisabled={editDisabled}
                prohibitedPhone={watch('applicant.cellPhone')}
              />
            )}
          </TabPanel>

          {globalErrors.length > 0 && (
            <Box display="flex" justifyContent="center">
              <Alert severity="error" sx={{ width: 'fit-content' }}>
                {globalErrors.map((error) => (
                  <Typography key={error.message}>
                    {translate(error.message, { minIncome: error.params!.minIncome })}
                  </Typography>
                ))}
              </Alert>
            </Box>
          )}

          <Grid container mb={5} justifyContent="flex-end">
            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-end"
              paddingRight={1}
              sx={{ marginTop: '5%', marginBottom: '1%' }}
            >
              <AsyncActionButton
                id="cancelNewApplicantion"
                variant="contained"
                color="error"
                onClick={() => {
                  navigate(`/credit/browse`)
                }}
              >
                {t('common.cancel')}
              </AsyncActionButton>
            </Grid>

            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-start"
              paddingLeft={1}
              sx={{ marginTop: '5%', marginBottom: '1%', marginRight: '1%' }}
            >
              <AsyncActionButton
                id="saveDraft"
                type="submit"
                variant="contained"
                value="draft"
                disabled={isPending && draftMod}
                isPending={isPending && draftMod}
                isError={isError && draftMod}
                color="secondary"
                onClick={() => {
                  setDraftMod(true)
                }}
              >
                {t('common.saveDraft')}
              </AsyncActionButton>
            </Grid>

            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-start"
              paddingLeft={1}
              sx={{ marginTop: '5%', marginBottom: '1%', marginRight: '1%' }}
            >
              <AsyncActionButton
                id="prequalify"
                type="button"
                variant="contained"
                value="prequalify"
                disabled={isPending && !draftMod}
                color="primary"
                isError={isError && !draftMod}
                isPending={isPending && !draftMod}
                onClick={() => {
                  setOpenConsentDialog(true)
                  setDraftMod(false)
                }}
              >
                {t('common.prequalify')}
              </AsyncActionButton>
              <CreditConsentDialog
                openConsentDialog={openConsentDialog}
                setOpenConsentDialog={setOpenConsentDialog}
                consentChecked={consentChecked}
                handleCheckboxChange={handleCheckboxChange}
                handleConfirm={handleConfirm}
                dialogContentText={<Typography>{t('submission.creditAgreementMessage')}</Typography>}
                buttonText={t('submission.submitPrequal')}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}
export default CreditApplicationForm
